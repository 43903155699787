<template>
	<div class="captcha">
		<div class="h-captcha" id="captcha-1"></div>
	</div>
</template>

<script setup>

onMounted(() => {
	const runtimeConfig = useRuntimeConfig();

	let script = document.createElement("script");
	script.src = "https://hcaptcha.com/1/api.js?render=explicit";
	script.async = true;
	script.defer = true;
	script.onload = () => {
		hcaptcha.render("captcha-1", {
			sitekey: runtimeConfig.public.hcaptchaSiteKey
		});
	}
	document.querySelector(".captcha").appendChild(script);

});
</script>